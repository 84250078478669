import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "gatsby";
import _ from "lodash";
import { fetchGuides } from "../../actions";
import GuideList from "../../collections/guides/guideList";
import { LinkButton, PageHeader, Column } from "../../common";

const Index = ({ update_view_modal_open, update_view_modal_data }) => {
  const dispatch = useDispatch();
  const guides = useSelector((state) => state.guides);

  useEffect(() => {
    dispatch(fetchGuides());
  }, []);

  function updateModal(data) {
    console.log(data);
    update_view_modal_data(data);
    update_view_modal_open(true);
  }

  function leftColumn() {
    const triage = _.filter(guides, { type: "Triage" });
    return (
      <div>
        <PageHeader title="Triage Guides" />
        <GuideList guides={triage} updateModal={updateModal} />
      </div>
    );
  }

  function rightColumn() {
    const contacts = _.filter(guides, { type: "Contact" });
    return (
      <>
        <PageHeader title="Contacts" />
        <GuideList guides={contacts} updateModal={updateModal} />
      </>
    );
  }

  return (
    <div>
      <PageHeader title={"Guides"}>
        <LinkButton to="/guides/new/" title="Create Guide" />
      </PageHeader>
      <Column left={leftColumn()} right={rightColumn()} />
    </div>
  );
};

export default Index;
