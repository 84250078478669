import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import Container from "../../common/components/container";
import Browse from "../../components/guides/browse";
import ViewModal from "../../components/guides/view/viewModal";

const Index = () => {
  const [view_modal_data, update_view_modal_data] = useState(null);
  const [view_modal_open, update_view_modal_open] = useState(false);
  return (
    <Layout>
      <Container>
        <Browse
          update_view_modal_open={update_view_modal_open}
          update_view_modal_data={update_view_modal_data}
        />
        <ViewModal
          open={view_modal_open}
          view_modal_data={view_modal_data}
          update_view_modal_open={update_view_modal_open}
          update_view_modal_data={update_view_modal_data}
        />
      </Container>
    </Layout>
  );
};

export default Index;
